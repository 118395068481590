import React from "react"

import Layout from "components/Layout/en"
import Image from "components/Image/index"
import { Container, Row, Col, Button } from "react-bootstrap"
import { Link } from "components/Layout/Link"
import imgHeader from "./header.jpg"

import imgToolbox from "./toolbox/product.png"
import imgWood from "./woodscanner/product.jpg"
import imgMetal from "./metalscanner/product.jpg"
import imgWeld from "./weldscanner/product.jpg"
import imgPaint from "./paintscanner/product.jpg"
import imgAssemblyControl from "./assemblycontrol/product.jpg"
import imgSurface from "./surfacescanner/product.jpg"
import imgLogistics from "./logisticscontrol/productLogisticsControl.jpg"

import styles from "styles/global.module.scss"

export default function(props) {
  const header = {
    x: 39,
    y: 49,
    text: "Deep Learning Products for Visual Inspection",
    description:
      "Automated visual inspection products for checkpoints in production lines and the analysis of surfaces of different material.",
    image: imgHeader,
  }
  return (
    <Layout header={header} location={props.location}>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image filename={imgSurface} alt="Moonvision SurfaceScanner" />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">MoonVision SurfaceScanner</small>
                <br />
                Industrial quality inspection of surfaces
              </h2>
              <p>
                Boost your quality management with the MoonVision
                SurfaceScanner. Defects on different surfaces are visually
                detected and differentiated fully automatically. The results are
                ideal products and significantly shortened quality inspections.
              </p>
              <Link to="/products/surfacescanner" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgLogistics}
                alt="Moonvision LogisticsControl"
              />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">
                  MoonVision LogisticsControl
                </small>
                <br />
                Pallet type detection in real time
              </h2>
              <p>
                Automate your logistics processes with the efficient empty
                container tracking system from MoonVision - through visual
                object recognition on vehicles (forklifts).
              </p>
              <Link to="/products/logisticscontrol" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                alt="Product WoodScanner with PC, Kamera und Holz"
                filename={imgWood}
              />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">MoonVision WoodScanner</small>
                <br />
                Automated surface inspection of wood
              </h2>
              <p>
                Determine precisely and automate the quality of logs before you
                process them. The MoonVision software uses visual data from
                cameras to check - whether their quality characteristics are
                fulfilled and which areas can be used for which applications.
              </p>
              <Link to="/products/woodscanner" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgMetal}
                alt="Product MetalScanner with PC, Kamera und Metallstücken"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">MoonVision MetalScanner</small>
                <br />
                Quality checks of metallic surfaces
              </h2>
              <p>
                Automate the differentiation of surface defects on metal to less
                than a second! The MoonVision software automatically
                differentiates between different surfaces and defect types and
                passes this information on where you need it most.
              </p>
              <Link to="/products/metalscanner" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgPaint}
                alt="Product PaintScanner with PC, Kamera und Lackstücken"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">MoonVision PaintScanner</small>
                <br />
                Defect detection on painted surfaces
              </h2>
              <p>
                Use the MoonVision PaintScanner to automatically inspect painted
                surfaces for defects and quality properties. By using visual
                data generated by cameras, you will be informed at an early
                stage whether your quality characteristics have been met and/or
                whether there is a need for action.
              </p>
              <Link to="/products/paintscanner" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgWeld}
                alt="Product WeldScanner with PC, Kamera und geschweißter Halterung"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">MoonVision WeldScanner</small>
                <br />
                Weld seam inspection & measurement
              </h2>
              <p>
                Reduce the time for measuring weld seams (e.g. according to DIN
                EN 17637) to less than one second! The MoonVision software
                automatically and precisely distinguishes the weld from the
                workpiece. Using a camera the system measures whether the result
                of the welding process fulfills your quality requirements.
                Different seam types are recognized and taken into account.
              </p>
              <Link to="/products/weldscanner" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgAssemblyControl}
                alt="Product AssemblyControl with PC, Kamera und elektronik teile"
              />
            </Col>
            <Col md="8" lg="7" className="pt-3 pt-md-0">
              <h2>
                <small className="text-muted">MoonVision AssemblyControl</small>
                <br />
                Digital assembly line inspection
              </h2>
              <p>
                With MoonVision AssemblyControl, you have a tool set for
                manufacturing at your disposal with which individually
                manufactured components can be automatically checked for their
                composition, positioning and alignment. On request, a
                target/actual comparison with your existing data sources can
                also be carried out. Customers benefit from a stable and
                reliable quality check in their production process and
                significantly shortened lead times.
              </p>
              <Link to="/products/assemblycontrol" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={styles.stripeSectors}>
        <Container>
          <Row className="">
            <Col md="4" lg="3">
              <Image
                filename={imgToolbox}
                alt="Product Moonvision Toolbox with PC"
              />
            </Col>
            <Col md="8" lg="7">
              <h2>
                <small className="text-muted">MoonVision Toolbox</small>
                <br />
                The Computer Vision Toolbox for Domain Experts
              </h2>
              <p>The most powerful toolset on the market.</p>
              <Link to="/products/toolbox" location={props.location}>
                <Button className="btn btn-primary">More information</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}
